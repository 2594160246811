import React from 'react'
import clsx from 'clsx'
import { PageProps } from 'gatsby'
import { Layout } from '../../../components/layout'
import { SEO } from '../../../components/seo'
import { Menu } from '../../../components/menu'

import * as styles from '../../../styles/pages/solutions.module.scss'
import mainHeader from '../../../images/solutions/controladoria/viabilidade.jpeg'

import { Contact } from '../../../components/contact/contact'
import { Footer } from '../../../components/footer'
import { FaqSection } from '../../../components/faq'
import { MethodologySection } from '../../../components/methodology'
import { SliderCustomers } from '../../../components/sliderCustomers/sliderCustomers'
import { StatisticsSection } from '../../../components/statistics'
import { controladoriaList } from '../../../utils/customersList'
import BreadcrumbComponent from '../../../components/breadcrumb'

const faqItems = [
  {
    question:
      'Qual é o prazo para conclusão de um projeto de análise de viabilidade econômica financeira?',
    answer: `Projetos de análise de viabilidade econômica financeira tem duração média de 45 dias, a depender do projeto a ser analisado. O estudo é feito a partir das premissas fornecidas pelo cliente e consequentemente, a veracidade e confiabilidade das informações é de sua respectiva responsabilidade.`,
  },
  {
    question:
      'O que é preciso para fazer um estudo de viabilidade econômica financeira de um projeto?',
    answer:
      'Para iniciar o estudo é necessário coletar dados detalhados sobre custos, receitas, investimentos iniciais e operacionais. Deve-se realizar projeções realistas, considerando fluxos de caixa futuros. A análise inclui cálculos de indicadores como VPL (valor presente líquido), TIR (taxa interna de retorno) e payback. Avaliar riscos, considerar variáveis econômicas e utilizar taxas de desconto são fundamentais. Com as premissas em mãos e um especialista no assunto, é possível vislumbrar cenários valiosos para a tomada de decisão bem fundamentada antes de iniciar um novo projeto.',
  },
  {
    question: 'Por que fazer um estudo de viabilidade econômica financeira?',
    answer:
      'Estudos de viabilidade econômica financeira fornecem insights sobre a lucratividade, retorno do investimento, riscos e benefícios associados ao projeto. Ele orienta a tomada de decisão, permitindo que gestores e investidores determinem se um projeto é viável e alinhado aos objetivos estratégicos da empresa. Também ajuda a identificar áreas de melhoria, mitigar riscos e otimizar recursos.',
  },
  {
    question:
      'Qual a diferença entre estudo de viabilidade econômica financeira e estudo de mercado?',
    answer:
      'O estudo de viabilidade econômica financeira e o estudo de mercado são fases distintas, embora interligadas, na análise de um projeto. O estudo de mercado concentra-se na análise do ambiente externo, avaliando a demanda, concorrência, tendências e aceitação do produto ou serviço, fornecendo informações sobre a viabilidade comercial. Já o estudo de viabilidade econômica financeira vai além, analisando aspectos internos, como custos, receitas, investimentos e retorno financeiro. Juntos, esses estudos fornecem uma visão abrangente da viabilidade de um projeto.',
  },
]

const methodologyItems = [
  {
    count: 1,
    title: 'Introdução e objetivos',
    description: (
      <>
        Na primeira etapa do estudo são coletadas as informações necessárias
        para elaboração da introdução e objetivos do projeto, através de
        entrevistas, informações de sites, redes sociais e checklists. São
        levantados dados históricos da empresa.
      </>
    ),
  },
  {
    count: 2,
    title: 'Validação de premissas',
    description: (
      <>
        Na segunda etapa são validadas as premissas com a empresa cliente e
        esclarecidas dúvidas. São levantadas todas as garantias e riscos para
        operacionalização do investimento e viabilidade do projeto, que irão
        embasar a simulação de cenários.
      </>
    ),
  },
  {
    count: 3,
    title: 'Projeções',
    description: (
      <>
        Nesta etapa são elaboradas as projeções de receitas, custos, despesas,
        investimentos, fluxo de caixa e indicadores, como valor presente
        líquido, taxa interna de retorno, payback e hedge financeiro. São
        analisadas alternativas que viabilizem o projeto.
      </>
    ),
  },
  {
    count: 4,
    title: 'Validação e entrega de cenários',
    description: (
      <>
        Nesta etapa são construídas e validadas análises de sensibilidade e
        cenários alternativos, que procuram estimar o resultado gerado por
        mudanças prováveis de ocorrerem, em custos, despesas e receitas. Cálculo
        é realizado conforme dados de projeção futura do segmento analisado.
      </>
    ),
  },
]

const featuresItems = [
  {
    title: `Viabilidade econômica financeira`,
    description: (
      <>
        A viabilidade econômica avalia os custos e benefícios de um projeto. Já
        a viabilidade financeira estima o valor total do investimento necessário
        para executar um projeto na prática, levando em consideração capital de
        giro, receitas, despesas, rendimentos, dentre outros.
      </>
    ),
  },
  {
    title: `Por que fazer esta análise?`,
    description: (
      <>
        A análise de viabilidade econômica financeira busca medir se um
        investimento é seguro ou não, levando em consideração os custos e
        benefícios de um projeto, como por exemplo: abertura de um novo negócio,
        lançamento de produtos, expansão de negócios, dentre outros.
      </>
    ),
  },
  {
    title: `Como é feita esta análise?`,
    description: (
      <>
        Ao analisar a viabilidade econômica financeira, devem ser observados
        pontos como a projeção de receitas, custos, despesas e investimentos,
        projeção de fluxo de caixa e indicadores, como o valor presente líquido,
        taxa interna de retorno, payback e retorno sobre o investimento.
      </>
    ),
  },
  {
    title: `Project Finance`,
    description: (
      <>
        É uma modalidade de viabilidade econômica financeira para realização de
        projetos de grande vulto, em que a principal fonte de receita para
        pagamento da dívida do financiamento do produto ou serviço resultante é
        obtida do fluxo de caixa gerado pela própria operação.
      </>
    ),
  },
]

const ControladoriaViabilidadePage: React.FC<PageProps> = ({ location }) => {
  const { pathname } = location
  return (
    <Layout location={location}>
      <SEO title="High Jump - Estudos de Viabilidade" />
      <div className={styles.solutions}>
        <Menu mainFolder={pathname} />
        <main>
          <section className={clsx(styles.intro, styles.singleTitle)}>
            <img src={mainHeader} alt="High jump Consulting" />
            <div className={clsx(styles.mainTitle)}>
              <h1>Análise de viabilidade econômica financeira de projetos</h1>
              <p>
                Tome decisões assertivas para captação de recursos, realizar
                investimentos, expandir seu negócio ou mesmo iniciar um novo
                projeto
              </p>
            </div>
          </section>
          <div className={styles.container}>
            <BreadcrumbComponent replacedLabel="Estudos de Viabilidade" />
            <div className={styles.intention}>
              <div className={styles.title}>
                <h1>Nosso Propósito</h1>
                <span></span>
                <p>
                  Realizar estudos de viabilidade econômica financeira de
                  projetos para embasar a tomada de decisões estratégicas
                </p>
              </div>
              <div className={styles.mainFeatures}>
                <div className={styles.listItems}>
                  {featuresItems.map((item, key) => (
                    <div className={styles.item} key={key}>
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <MethodologySection
              items={methodologyItems}
              descriptionSection="Conheça os 04 principais passos de nossa metodologia para realizar estudos de viabilidade econômico financeiros"
            />
          </div>

          <div className={styles.cases}>
            <div className={styles.title}>
              <h1>Quem conhece, confia!</h1>
            </div>
            <SliderCustomers customers={controladoriaList} />
          </div>

          <div className={styles.statistics}>
            <StatisticsSection />
          </div>

          <div className={styles.container}>
            <div className={styles.features}>
              <div className={styles.title}>
                <h1>Diferenciais</h1>
              </div>
              <div className={styles.items}>
                <div className={styles.div1}>
                  <h2>Equipe multidisciplinar</h2>
                  <p>
                    Especialistas por área, trazendo as melhores práticas de
                    mercado
                  </p>
                </div>
                <div className={styles.div2}>
                  <h2>Boas práticas FIPECAFI</h2>
                  <p>Aplicação de boas práticas contábeis e fiscais</p>
                </div>
                <div className={styles.div3}>
                  <h2>Confiança no resultado</h2>
                  <p>Almejando o retorno sobre o investimento</p>
                </div>
                <div className={styles.div4}>
                  <h2>Modalidade híbrida</h2>
                  <p>
                    Execução presencial e/ou remota em comum acordo com o
                    cliente
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Contact locationPath={pathname} />
          <FaqSection items={faqItems} />
          <Footer />
        </main>
      </div>
    </Layout>
  )
}

export default ControladoriaViabilidadePage
